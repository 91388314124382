import React, { useState } from "react";
import styles from "./Header.module.scss";
import { ChevronDown, Hamburger, Logo, MobileLogo } from "../../icons";
import { Link, useNavigate } from "react-router-dom";
import Button from "../button/Button";
import SideMenu from "../SideMenu/SideMenu";
import { routerConstant } from "../../contants";

const Header = () => {
  const navlink = [
    {
      to: routerConstant.ROUTES.HOME,
      title: "Home",
    },
    {
      to: routerConstant.ROUTES.ABOUT,
      title: "about",
    },
    {
      to: routerConstant.ROUTES.SERVICES,
      title: "services",
      subPages: [
        {
          to: "comprehensive-it-solutions",
          title: "Comprehensive IT Solutions",
        },
        {
          to: "hpc-consulting-and-solution-design",
          title: "HPC Consulting and Solution Design",
        },
        {
          to: "data-center-services",
          title: "Data Center Consulting and Solution Design",
        },
        {
          to: "network-design-storage-and-security",
          title: "Network Design, Storage and Security",
        },
      ],
    },
  ];

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = () => {
    setIsOpen(!isOpen);
  };
  const handleClick = () => {
    navigate(routerConstant.ROUTES.CONTACT_US);
  };

  return (
    <nav className={styles.Header}>
      <Link to={"/"} className={styles.logo}>
        <Logo />
      </Link>
      <Link to={"/"} className={styles.mobileLogo}>
        <MobileLogo />
      </Link>
      <div className={styles.menu} onClick={handleChange}>
        <Hamburger />
      </div>

      <ul className={styles.listItem}>
        {navlink.map((item, index) => (
          <li key={index} className={styles.menuItem}>
            <Link className={styles.item} to={item.to}>
              {item.title}
              {item.title === "services" && (
                <ChevronDown className={styles.chevron} />
              )}
            </Link>
            {item.title === "services" && (
              <div className={styles.popUpMenu}>
                {item.subPages.map((subPage, index) => (
                  <Link
                    state={{ to: subPage.to }}
                    key={index}
                    to={`${routerConstant.ROUTES.SERVICES}`}
                    className={styles.popUpItem}
                  >
                    {subPage.title}
                  </Link>
                ))}
              </div>
            )}
          </li>
        ))}
      </ul>
      <SideMenu handleChange={handleChange} isOpen={isOpen} />
      <Button className={styles.btn} onClick={handleClick}>
        Contact Us
      </Button>
    </nav>
  );
};

export default Header;
