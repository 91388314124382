import * as React from "react";
const Line = (props) => (
  <svg
    width={173}
    height={17}
    viewBox="0 0 173 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 11.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 46.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 81.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 116.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 151.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 18.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 53.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 88.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 123.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 158.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 25.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 60.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 95.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 130.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 165.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 32.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 67.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 102.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 137.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 172.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 39.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 74.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 109.5 1.29346)"
      stroke="#D4D4D8"
    />
    <line
      y1={-0.5}
      x2={18.0278}
      y2={-0.5}
      transform="matrix(-0.5547 0.83205 0.83205 0.5547 144.5 1.29346)"
      stroke="#D4D4D8"
    />
  </svg>
);
export default Line;
