import React from "react";
import { ArrowLeft } from "../../icons";
import styles from "./IconWithDesc.module.scss";
import cx from "classnames";

const IconWithDesc = (props) => {
  const {
    isActive,
    desc,
    onClick,
    icon,
    textColor,
    className,
    textClassName,
    type,
  } = props;
  return (
    <div
      className={cx(styles.IconWithDesc, className, {
        [styles.active]: isActive,
      })}
      onClick={onClick}
    >
      <p
        className={cx(styles.desc, textClassName)}
        style={{
          color: textColor,
        }}
      >
        {desc}
      </p>
      {icon ? icon : <ArrowLeft />}
      {type && (
        <a href={"/services"} className={styles.btn}>
          View
        </a>
      )}
    </div>
  );
};

export default IconWithDesc;
