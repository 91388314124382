import React, { useState } from "react";
import styles from "./HomeSlide5.module.scss";
import box1 from "../../assets/hsol1.png";
import box2 from "../../assets/hsol2.png";
import box3 from "../../assets/hsol3.png";
import box4 from "../../assets/hsol3.png";
import bg from "../../assets/clipPath.png";
import IconWithDesc from "../IconWithDesc/IconWithDesc";
import { LeftIcon } from "../../icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../contants/RouterContant";

const HomeSlide5 = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  // subPages: [
  //   {
  //     to: "comprehensive-it-solutions",
  //     title: "Comprehensive IT Solutions",
  //   },
  //   {
  //     to: "hpc-consulting-and-solution-design",
  //     title: "HPC Consulting and Solution Design",
  //   },
  //   {
  //     to: "data-center-services",
  //     title: "Data Center Consulting and Solution Design"
  //   },
  //   {
  //     to: "network-design-storage-and-security",
  //     title: "Network Design, Storage and Security"
  //   }
  // ]

  const imageTitle = [
    {
      image: box1,
      title: "Comprehensive IT Solutions",
    },
    {
      image: box2,
      title: "HPC Consulting and Solution Design",
    },
    {
      image: box3,
      title: "Data Center Consulting and Solution Design",
    },
    {
      image: box4,
      title: "Network Design, Storage and Security",
    },
  ];

  const descriptionSets = [
    [
      "Customized solution design",
      "Performance optimization and workload analysis",
      "Architecture planning and system integration",
    ],
    [
      "Cloud-based deployment and scaling",
      "Data security and compliance solutions",
      "User experience design and testing",
    ],
    [
      "API development and integration",
      "Monitoring and alerting solutions",
      "Team collaboration and project management",
    ],
    [
      "Custom Network Design",
      "Proactive Monitoring and Maintenance",
      "Advanced Security Solutions",
    ],
    // Add more sets as needed
  ];

  const navigation = useNavigate();
  const handleClick = () => {
    navigation(ROUTES.SERVICES);
  };

  return (
    <div className={styles.HomeSlide5}>
      <div className={styles.bg}>
        <img src={bg} alt="background" className={styles.image} />
      </div>
      <div className={styles.upperDiv}>
        <h6 className={styles.title}>Comprehensive IT Solutions</h6>
        <p className={styles.desc}>
          We offer a wide range of services to meet your IT infrastructure
          needs, including:
        </p>
      </div>
      <div className={styles.lowerDiv}>
        {imageTitle.map((item, index) => (
          <div
            className={styles.imageContainer}
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <img src={item.image} alt={item.title} className={styles.image} />
            <p className={styles.title}>{item.title}</p>
            {/* {hoveredIndex === index &&
              descriptionSets[hoveredIndex].map((desc, i) => (
                <IconWithDesc
                  key={i}
                  desc={desc}
                  textColor={"#000"}
                  icon={<LeftIcon />}
                  className={styles.singleItem}
                  textClassName={styles.text}
                />
              ))} */}
            {hoveredIndex === index && (
              <div className={styles.box}>
                <IconWithDesc
                  desc={descriptionSets[index][0]}
                  textColor={"#000"}
                  icon={<LeftIcon />}
                  className={styles.singleItem}
                  textClassName={styles.text}
                  onClick={handleClick}
                  type
                />
                <IconWithDesc
                  desc={descriptionSets[index][1]}
                  textColor={"#000"}
                  icon={<LeftIcon />}
                  className={styles.singleItem}
                  textClassName={styles.text}
                  onClick={handleClick}
                  type
                />
                <IconWithDesc
                  desc={descriptionSets[index][2]}
                  textColor={"#000"}
                  icon={<LeftIcon />}
                  className={styles.singleItem}
                  textClassName={styles.text}
                  onClick={handleClick}
                  type
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.con}>
        <button className={styles.btn} onClick={handleClick}>
          Explore Our Services
        </button>
      </div>
    </div>
  );
};

export default HomeSlide5;
