import React from "react";
import styles from "./HomeSlide4.module.scss";
import bg1 from "../../assets/slide4bg1.png";
import bg2 from "../../assets/slide4bg2.png";

const HomeSlide4 = () => {
  const details = [
    {
      title: `${new Date().getFullYear() - 2019}`,
      desc1: "Years",
      desc2: "Started in April 2019",
    },
    {
      title: "65+",
      desc1: "Projects",
      desc2:
        "More then 65 Projects Completed successfully with 100% customer satisfaction",
    },
    {
      title: "50+",
      desc1: "Clients",
      desc2: "More then 50 clients served",
    },
    {
      title: "20+",
      desc1: "Team",
      desc2:
        "More then 20 highly skilled IT professionals with more then 10 years of experience in IT",
    },
  ];
  return (
    <div className={styles.HomeSlide4}>
      <div className={styles.bg1}>
        <img src={bg2} alt="" className={styles.image} />
      </div>
      <div className={styles.box}>
        <h4 className={styles.title}>
          Your Business, Our Expertise – Together We Succeed
        </h4>
        <div className={styles.details}>
          {details.map((item, index) => (
            <div className={styles.titleWithdes} key={`${index}item`}>
              <p className={styles.title}>{item.title}</p>
              <p className={styles.desc1}>{item.desc1}</p>
              <p className={styles.desc2}>{item.desc2}</p>
            </div>
          ))}
        </div>
        {/* <Button className={styles.btn}>get started</Button> */}
      </div>
      <div className={styles.bg2}>
        <img src={bg1} alt="" className={styles.image} />
      </div>
    </div>
  );
};

export default HomeSlide4;
