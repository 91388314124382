import * as React from "react";
const Location = (props) => (
  <svg
    width={92}
    height={93}
    viewBox="0 0 92 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M84.1529 65.4824C82.8478 64.7656 81.2092 65.2423 80.4928 66.5469C79.776 67.8516 80.2526 69.4903 81.5573 70.2069C84.7679 71.9707 86.6094 73.9261 86.6094 75.5717C86.6094 77.5844 83.7577 80.7037 75.7539 83.4229C67.8468 86.1096 57.2799 87.589 46 87.589C34.7201 87.589 24.1532 86.1096 16.2461 83.4229C8.24227 80.7039 5.39062 77.5844 5.39062 75.5717C5.39062 73.9261 7.23206 71.9707 10.4427 70.2067C11.7474 69.4902 12.224 67.8514 11.5072 66.5467C10.7904 65.2422 9.1522 64.7655 7.84713 65.4822C4.2674 67.4489 0 70.7958 0 75.5717C0 79.2326 2.51778 84.4522 14.5119 88.5271C22.963 91.3984 34.1457 92.9796 46 92.9796C57.8543 92.9796 69.037 91.3984 77.4881 88.5271C89.4822 84.4522 92 79.2326 92 75.5717C92 70.7958 87.7326 67.4489 84.1529 65.4824Z"
      fill="white"
    />
    <path
      d="M25.6505 79.5264C31.122 81.0721 38.3489 81.9234 45.9998 81.9234C53.6507 81.9234 60.8775 81.0723 66.349 79.5264C73.0429 77.6356 76.437 74.8982 76.437 71.3911C76.437 67.8837 73.0429 65.1467 66.349 63.2559C64.863 62.8361 63.2469 62.468 61.5287 62.1539C60.5956 63.766 59.6179 65.4254 58.5957 67.1327C60.4975 67.418 62.283 67.7678 63.9045 68.1809C68.8309 69.4357 70.6306 70.8482 71.0006 71.3912C70.6304 71.9344 68.831 73.347 63.9047 74.6015C59.242 75.7891 53.243 76.4665 46.9246 76.5271C46.6186 76.5497 46.3103 76.5625 45.9998 76.5625C45.6891 76.5625 45.3809 76.5497 45.0747 76.5271C38.7564 76.4665 32.7573 75.7893 28.0946 74.6015C23.1683 73.347 21.3687 71.9343 20.9988 71.3912C21.3687 70.8482 23.1685 69.4357 28.0948 68.1809C29.7163 67.768 31.5019 67.418 33.4039 67.1327C32.3816 65.4256 31.4039 63.7658 30.4708 62.1539C28.7526 62.4681 27.1364 62.8361 25.6505 63.2559C18.9566 65.1467 15.5625 67.8839 15.5625 71.3911C15.5625 74.8982 18.9566 77.6354 25.6505 79.5264Z"
      fill="white"
    />
    <path
      d="M46 71.1717C48.3993 71.1717 50.5788 69.9491 51.8305 67.9012C60.601 53.5519 71.0556 34.6405 71.0556 26.0351C71.0556 12.2194 59.8158 0.979614 46 0.979614C32.1841 0.979614 20.9443 12.2194 20.9443 26.0351C20.9443 34.6405 31.3991 53.5519 40.1695 67.9012C41.4212 69.9491 43.6008 71.1717 46 71.1717ZM35.929 24.3147C35.929 18.7619 40.4469 14.2441 46 14.2441C51.553 14.2441 56.0709 18.7619 56.0709 24.3147C56.0709 29.8678 51.553 34.3855 46 34.3855C40.4469 34.3855 35.929 29.868 35.929 24.3147Z"
      fill="white"
    />
  </svg>
);
export default Location;
